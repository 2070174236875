import { MoreOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "./register.module.css";
import { useSizes } from "../hooks/useSizes";
import { Checkbox, Modal, Space } from "antd";
import { Button } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";

const Register = () => {
  const { t } = useTranslation();
  var currentTime = new Date();
  const { id } = useParams();
  // const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [supportBrowser, setSupportBrowser] = useState(false);
  const [nameSupport, setNameSupport] = useState(null);

  let urlhref = window.location.href;
  let url = new URL(urlhref);
  let referral = url.searchParams.get("ref_code") ?? id;
  let route = url.searchParams.get("route");
  const { height } = useSizes();
  const [status, setStatus] = useState(false);
  const [accept, setAccept] = useState(false);
  const base_login_url = process.env.REACT_APP_AUTH_URI;
  const userAgent = window.navigator.userAgent;

  const isInApp = (appSpecificUserAgents) => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    for (let i = 0; i <= appSpecificUserAgents.length; i++) {
      if (
        userAgent.indexOf(appSpecificUserAgents[i]) > -1 ||
        userAgent.includes(appSpecificUserAgents)
      )
        return true;
    }
  };

  useEffect(() => {
    if (document.body) {
      if (isInApp(["FBAN", "FBAV"])) {
        setIsModalOpen(true);
      }
      if (isInApp(["Instagram"])) {
        setSupportBrowser(true);
        setNameSupport("Instagram");
      }
      if (isInApp(["Snapchat"])) {
        setSupportBrowser(true);
        setNameSupport("Snapchat");
      }
    }
  }, [urlhref, userAgent]);

  const auth_google = `/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_API_URI}&response_type=CODE&client_id=${process.env.REACT_APP_CLIENT_ID}&state=${referral}&scope=aws.cognito.signin.user.admin email openid phone profile`;
  const auth_apple = `/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${process.env.REACT_APP_API_URI}&response_type=CODE&client_id=${process.env.REACT_APP_CLIENT_ID}&state=${referral}&scope=aws.cognito.signin.user.admin email openid phone profile`;
  useEffect(() => {
    if (route) {
      axios.get(
        process.env.REACT_APP_MOBILE_API_URI +
          `/user/inflow_route?route=${route}`
      );
    }
  }, [route]);

  return (
    <div className={styles.mainContent} style={{ minHeight: height }}>
      <Modal
        open={isModalOpen}
        footer={false}
        closable={false}
        title={false}
        header={false}
        centered
      >
        <div style={{ textAlign: "center" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={300}
            height={60}
            viewBox="0 0 159 31"
            fill="none"
          >
            <path
              d="M113.546 9.06738L110.909 18.8923C110.804 19.2747 110.458 19.5383 110.054 19.5383H110.012C109.614 19.5383 109.263 19.2747 109.158 18.8923L107.412 12.5146C107.312 12.158 107.15 11.4189 107.087 11.0365C107.029 11.4138 106.867 12.1528 106.762 12.5146L105.016 18.8923C104.911 19.2696 104.565 19.5383 104.162 19.5383C103.763 19.5383 103.412 19.2747 103.307 18.8923L100.67 9.06738H102.227L103.889 15.9877C103.968 16.3443 104.109 17.0834 104.193 17.4658C104.272 17.0885 104.434 16.3495 104.539 15.9877L106.442 9.06738H107.816L109.719 15.9877C109.818 16.3443 109.981 17.0834 110.065 17.4658C110.143 17.0885 110.285 16.3495 110.369 15.9877L112.031 9.06738H113.546Z"
              fill="#231815"
            />
            <path
              d="M139.701 10.1481C138.123 10.1481 137.033 11.2645 136.849 13.6005H140.566V14.8151H136.849C136.991 17.0478 138.065 18.4639 139.806 18.4639C141.038 18.4639 141.767 18.1021 142.742 17.4664L143.45 18.5414C142.176 19.3373 141.243 19.7559 139.643 19.7559C137.698 19.7559 135.334 18.3605 135.334 14.2517C135.334 10.9182 137.331 8.8457 139.864 8.8457C141.463 8.8457 142.254 9.26433 143.534 10.0602L142.826 11.1352C141.856 10.4944 141.127 10.1378 139.712 10.1378L139.701 10.1481Z"
              fill="#231815"
            />
            <path
              d="M123.208 19.5435H121.688V18.2669C120.938 19.0628 119.884 19.7605 118.369 19.7605C116.707 19.7605 114.972 18.9439 114.972 16.4115C114.972 14.0599 116.932 13.0624 119.04 13.0624C120.115 13.0624 121.064 13.3209 121.693 13.662V12.4267C121.693 10.8711 120.943 10.1114 119.386 10.1114C118.275 10.1114 117.362 10.4111 116.492 10.9073L115.926 9.69273C116.859 9.19657 118.112 8.85547 119.486 8.85547C121.771 8.85547 123.208 9.8116 123.208 12.2459V19.5486V19.5435ZM121.688 16.9697V14.8558C121.001 14.4786 120.251 14.2563 118.956 14.2563C117.399 14.2563 116.466 14.9954 116.466 16.3133C116.466 17.8689 117.336 18.546 118.631 18.546C120.005 18.546 121.037 17.8483 121.688 16.9697Z"
              fill="#231815"
            />
            <path
              d="M127.135 19.5435C126.716 19.5435 126.375 19.2076 126.375 18.7941V5.83726L127.89 5V18.7941C127.89 19.2076 127.549 19.5435 127.13 19.5435H127.135Z"
              fill="#231815"
            />
            <path
              d="M131.895 19.5435C131.476 19.5435 131.135 19.2076 131.135 18.7941V5.83726L132.65 5V18.7941C132.65 19.2076 132.309 19.5435 131.89 19.5435H131.895Z"
              fill="#231815"
            />
            <path
              d="M150.989 10.3285H148.357V17.1506C148.357 18.0861 148.619 18.4065 149.432 18.4065C150.04 18.4065 150.444 18.3083 150.931 18.1067L150.832 19.404C150.244 19.6417 149.678 19.7606 148.871 19.7606C147.555 19.7606 146.847 19.1197 146.847 17.3884V10.3285H145.531V9.0726H146.847V5.83726L148.368 5V9.06743H150.999V10.3233L150.989 10.3285Z"
              fill="#231815"
            />
            <path
              d="M15.5333 12.427C15.5333 18.4532 20.4248 12.1427 20.4248 19.5385H17.0799C17.0799 11.2124 12.1885 18.2878 12.1885 12.427C12.1885 6.56615 17.0799 13.6415 17.0799 5.31543H20.4248C20.4248 12.7112 15.5333 6.40077 15.5333 12.427Z"
              fill="#231815"
            />
            <path
              d="M16.2363 18.1897C16.2363 12.3288 11.3448 19.4042 11.3448 11.0781H8C8 18.4739 12.8914 12.1635 12.8914 18.1897C12.8914 24.2159 8 17.9054 8 25.3012H11.3448C11.3448 16.9751 16.2363 24.0505 16.2363 18.1897Z"
              fill="#231815"
            />
            <path
              d="M34.1352 12.4321C34.9058 13.595 35.6817 14.7785 36.4524 15.9931C37.2231 17.2025 37.9938 18.386 38.7592 19.5437H37.0082L33.2753 13.7501L29.5006 19.5437H27.7705L32.3946 12.4063L27.802 5.31543H29.5006L33.2544 11.0884L37.0082 5.31543H38.7592L34.1352 12.4321Z"
              fill="#231815"
            />
            <path
              d="M50.9065 5.31543V6.69536H43.2207C43.0005 6.69536 42.817 6.87108 42.817 7.09332V11.507H48.0912V12.8818H42.817V17.7658C42.817 17.9829 42.9953 18.1638 43.2207 18.1638H51.2682V19.5385H42.6912C41.9153 19.5385 41.2861 18.9183 41.2861 18.1534V6.70053C41.2861 5.93562 41.9153 5.31543 42.6912 5.31543H50.9065Z"
              fill="#231815"
            />
            <path
              d="M56.5953 5.95113L63.5629 17.6159V5.31543H65.0938V18.2568C65.0938 18.9648 64.5118 19.5437 63.7883 19.5437C63.327 19.5437 62.8971 19.3008 62.6664 18.9132L55.6988 7.2587V19.5437H54.168V6.60233C54.168 5.89428 54.7499 5.31543 55.4734 5.31543C55.9348 5.31543 56.3647 5.55834 56.5953 5.94596V5.95113Z"
              fill="#231815"
            />
            <path
              d="M78.4983 5.31543V6.69536H70.8125C70.5923 6.69536 70.4088 6.87108 70.4088 7.09332V11.507H75.6777V12.8818H70.4088V17.7658C70.4088 17.9829 70.587 18.1638 70.8125 18.1638H78.86V19.5385H70.283C69.5071 19.5385 68.8779 18.9183 68.8779 18.1534V6.70053C68.8779 5.93562 69.5071 5.31543 70.283 5.31543H78.4983Z"
              fill="#231815"
            />
            <path
              d="M91.8368 19.5437L87.2966 7.23803C87.2337 7.06231 86.982 7.06231 86.9191 7.23803L82.3685 19.5437H80.7852L85.7081 6.29223C85.9282 5.70822 86.4892 5.31543 87.1236 5.31543C87.758 5.31543 88.3242 5.70822 88.5391 6.29223L93.4201 19.5385H91.8263L91.8368 19.5437Z"
              fill="#231815"
            />
          </svg>
        </div>

        <div
          style={{
            fontSize: 28,
            fontWeight: 600,
            textAlign: "center",
            marginBottom: 10,
          }}
        >
          {t("Xenea Support")}
        </div>
        <Space direction="vertical">
          <span>
            {t("content_default_browser", {
              social:
                nameSupport === "Message" ? "Facebook Message" : "Facebook",
            })}
            <div>{t("instructions")}</div>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginLeft: 0,
              paddingInlineStart: 30,
            }}
          >
            <li>
              {t("Tap the three dots")} <MoreOutlined />
              {t("in the top right corner")}
              <div style={{ textAlign: "center" }}>
                <img
                  src={t(
                    nameSupport === "Message" ? "message_navbar" : "face_navbar"
                  )}
                  alt="face_right"
                  width="100%"
                />
              </div>
            </li>
            <li>
              <Trans i18nKey={"select_open_external"} t={t}>
                Select <b> Open in external browser </b>
              </Trans>
              <div style={{ textAlign: "center" }}>
                <img
                  src={t(
                    nameSupport === "Message"
                      ? "message_external"
                      : "face_external"
                  )}
                  alt="load"
                  width="100%"
                />
              </div>
            </li>
          </ul>
        </Space>
      </Modal>
      <div className={styles.mainLeft}>
        <img
          // src="https://xenea-prod-public-files-bucket.s3.ap-southeast-1.amazonaws.com/xenea-logo-white.png"
          src={"/logo_xeneawallet.png"}
          alt="Your logo blue"
          className={styles.logoApp}
        />
        {/* <div className={styles.textOmega}>Xenea Wallet</div> */}
      </div>
      <div className={styles.mainRight}>
        <div>
          <img
            src="https://xenea-prod-public-files-bucket.s3.ap-southeast-1.amazonaws.com/omega-wallet.png"
            width="200"
            style={{ marginBottom: 15 }}
            alt="Your logo goes here"
          />

          <h1 className="title" id="title">
            {status ? "Login" : " Sign up"}
          </h1>
        </div>
        <div className={styles.content}>
          <div className={styles.signupContainer}>
            {!status && (
              <div> {referral && <div>Referral code: {referral}</div>}</div>
            )}

            <Checkbox
              className={styles.Checkbox}
              onChange={(value) => {
                setAccept(value.target.checked);
              }}
            >
              I accept{" "}
              <a href="https://xenea.app/Terms-of-Use" className="privacy-link">
                Terms and Service
              </a>{" "}
              and{" "}
              <a
                href="https://xenea.app/PrivacyPolicy.html"
                className="privacy-link"
              >
                Privacy Policy
              </a>
            </Checkbox>
          </div>
          <div>
            <div className={styles.divButton}>
              <Button
                disabled={!accept}
                onClick={() => {
                  window.location = base_login_url + auth_google;
                }}
                className={styles.btnLoginGoogle}
              >
                <svg
                  width="25px"
                  height="25px"
                  viewBox="-3 0 262 262"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid"
                >
                  <path
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                    fill="#4285F4"
                  />
                  <path
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                    fill="#34A853"
                  />
                  <path
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                    fill="#FBBC05"
                  />
                  <path
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                    fill="#EB4335"
                  />
                </svg>{" "}
                Continue with Google
              </Button>{" "}
              <Button
                disabled={!accept}
                onClick={() => {
                  window.location = base_login_url + auth_apple;
                }}
                className={styles.btnLoginApple}
              >
                <svg
                  fill="#ffffff"
                  width="25px"
                  height="25px"
                  viewBox="-52.01 0 560.035 560.035"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655" />
                </svg>{" "}
                Continue with Apple
              </Button>
            </div>
          </div>
          {/* <div
            id="btn-action-wrapper"
            class="create-acc"
            className={styles.create_acc}
            onClick={() => {
              setStatus(!status);
            }}
          >
            {status ? (
              <div>
                {" "}
                If you do not have an account yet,{" "}
                <a class="textLink">sign up</a>
              </div>
            ) : (
              <div>
                If you have an account, <a class="textLink">sign in</a> here
                here
              </div>
            )}
            <div class="btn-action"></div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                fill="#777777"
              />
            </svg>
          </div> */}
        </div>
        <p className={styles.footerSecurity}>
          &copy; {currentTime.getFullYear()} Xenea Wallet
        </p>
      </div>
      <Modal
        open={supportBrowser}
        footer={false}
        closable={false}
        title={false}
        header={false}
        centered
      >
        <div style={{ textAlign: "center" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={300}
            height={60}
            viewBox="0 0 159 31"
            fill="none"
          >
            <path
              d="M113.546 9.06738L110.909 18.8923C110.804 19.2747 110.458 19.5383 110.054 19.5383H110.012C109.614 19.5383 109.263 19.2747 109.158 18.8923L107.412 12.5146C107.312 12.158 107.15 11.4189 107.087 11.0365C107.029 11.4138 106.867 12.1528 106.762 12.5146L105.016 18.8923C104.911 19.2696 104.565 19.5383 104.162 19.5383C103.763 19.5383 103.412 19.2747 103.307 18.8923L100.67 9.06738H102.227L103.889 15.9877C103.968 16.3443 104.109 17.0834 104.193 17.4658C104.272 17.0885 104.434 16.3495 104.539 15.9877L106.442 9.06738H107.816L109.719 15.9877C109.818 16.3443 109.981 17.0834 110.065 17.4658C110.143 17.0885 110.285 16.3495 110.369 15.9877L112.031 9.06738H113.546Z"
              fill="#231815"
            />
            <path
              d="M139.701 10.1481C138.123 10.1481 137.033 11.2645 136.849 13.6005H140.566V14.8151H136.849C136.991 17.0478 138.065 18.4639 139.806 18.4639C141.038 18.4639 141.767 18.1021 142.742 17.4664L143.45 18.5414C142.176 19.3373 141.243 19.7559 139.643 19.7559C137.698 19.7559 135.334 18.3605 135.334 14.2517C135.334 10.9182 137.331 8.8457 139.864 8.8457C141.463 8.8457 142.254 9.26433 143.534 10.0602L142.826 11.1352C141.856 10.4944 141.127 10.1378 139.712 10.1378L139.701 10.1481Z"
              fill="#231815"
            />
            <path
              d="M123.208 19.5435H121.688V18.2669C120.938 19.0628 119.884 19.7605 118.369 19.7605C116.707 19.7605 114.972 18.9439 114.972 16.4115C114.972 14.0599 116.932 13.0624 119.04 13.0624C120.115 13.0624 121.064 13.3209 121.693 13.662V12.4267C121.693 10.8711 120.943 10.1114 119.386 10.1114C118.275 10.1114 117.362 10.4111 116.492 10.9073L115.926 9.69273C116.859 9.19657 118.112 8.85547 119.486 8.85547C121.771 8.85547 123.208 9.8116 123.208 12.2459V19.5486V19.5435ZM121.688 16.9697V14.8558C121.001 14.4786 120.251 14.2563 118.956 14.2563C117.399 14.2563 116.466 14.9954 116.466 16.3133C116.466 17.8689 117.336 18.546 118.631 18.546C120.005 18.546 121.037 17.8483 121.688 16.9697Z"
              fill="#231815"
            />
            <path
              d="M127.135 19.5435C126.716 19.5435 126.375 19.2076 126.375 18.7941V5.83726L127.89 5V18.7941C127.89 19.2076 127.549 19.5435 127.13 19.5435H127.135Z"
              fill="#231815"
            />
            <path
              d="M131.895 19.5435C131.476 19.5435 131.135 19.2076 131.135 18.7941V5.83726L132.65 5V18.7941C132.65 19.2076 132.309 19.5435 131.89 19.5435H131.895Z"
              fill="#231815"
            />
            <path
              d="M150.989 10.3285H148.357V17.1506C148.357 18.0861 148.619 18.4065 149.432 18.4065C150.04 18.4065 150.444 18.3083 150.931 18.1067L150.832 19.404C150.244 19.6417 149.678 19.7606 148.871 19.7606C147.555 19.7606 146.847 19.1197 146.847 17.3884V10.3285H145.531V9.0726H146.847V5.83726L148.368 5V9.06743H150.999V10.3233L150.989 10.3285Z"
              fill="#231815"
            />
            <path
              d="M15.5333 12.427C15.5333 18.4532 20.4248 12.1427 20.4248 19.5385H17.0799C17.0799 11.2124 12.1885 18.2878 12.1885 12.427C12.1885 6.56615 17.0799 13.6415 17.0799 5.31543H20.4248C20.4248 12.7112 15.5333 6.40077 15.5333 12.427Z"
              fill="#231815"
            />
            <path
              d="M16.2363 18.1897C16.2363 12.3288 11.3448 19.4042 11.3448 11.0781H8C8 18.4739 12.8914 12.1635 12.8914 18.1897C12.8914 24.2159 8 17.9054 8 25.3012H11.3448C11.3448 16.9751 16.2363 24.0505 16.2363 18.1897Z"
              fill="#231815"
            />
            <path
              d="M34.1352 12.4321C34.9058 13.595 35.6817 14.7785 36.4524 15.9931C37.2231 17.2025 37.9938 18.386 38.7592 19.5437H37.0082L33.2753 13.7501L29.5006 19.5437H27.7705L32.3946 12.4063L27.802 5.31543H29.5006L33.2544 11.0884L37.0082 5.31543H38.7592L34.1352 12.4321Z"
              fill="#231815"
            />
            <path
              d="M50.9065 5.31543V6.69536H43.2207C43.0005 6.69536 42.817 6.87108 42.817 7.09332V11.507H48.0912V12.8818H42.817V17.7658C42.817 17.9829 42.9953 18.1638 43.2207 18.1638H51.2682V19.5385H42.6912C41.9153 19.5385 41.2861 18.9183 41.2861 18.1534V6.70053C41.2861 5.93562 41.9153 5.31543 42.6912 5.31543H50.9065Z"
              fill="#231815"
            />
            <path
              d="M56.5953 5.95113L63.5629 17.6159V5.31543H65.0938V18.2568C65.0938 18.9648 64.5118 19.5437 63.7883 19.5437C63.327 19.5437 62.8971 19.3008 62.6664 18.9132L55.6988 7.2587V19.5437H54.168V6.60233C54.168 5.89428 54.7499 5.31543 55.4734 5.31543C55.9348 5.31543 56.3647 5.55834 56.5953 5.94596V5.95113Z"
              fill="#231815"
            />
            <path
              d="M78.4983 5.31543V6.69536H70.8125C70.5923 6.69536 70.4088 6.87108 70.4088 7.09332V11.507H75.6777V12.8818H70.4088V17.7658C70.4088 17.9829 70.587 18.1638 70.8125 18.1638H78.86V19.5385H70.283C69.5071 19.5385 68.8779 18.9183 68.8779 18.1534V6.70053C68.8779 5.93562 69.5071 5.31543 70.283 5.31543H78.4983Z"
              fill="#231815"
            />
            <path
              d="M91.8368 19.5437L87.2966 7.23803C87.2337 7.06231 86.982 7.06231 86.9191 7.23803L82.3685 19.5437H80.7852L85.7081 6.29223C85.9282 5.70822 86.4892 5.31543 87.1236 5.31543C87.758 5.31543 88.3242 5.70822 88.5391 6.29223L93.4201 19.5385H91.8263L91.8368 19.5437Z"
              fill="#231815"
            />
          </svg>
        </div>

        <div
          style={{
            fontSize: 28,
            fontWeight: 600,
            textAlign: "center",
            marginBottom: 10,
          }}
        >
          {t("Xenea Support")}
        </div>
        <Space direction="vertical">
          <span>
            {t("content_default_browser", {
              social: nameSupport === "Instagram" ? "Instagram" : "Snapchat",
            })}
            <div>{t("instructions")}</div>
          </span>
          <ul
            style={{
              marginBottom: 0,
              marginLeft: 0,
              paddingInlineStart: 30,
            }}
          >
            <li>
              {t("Tap the three dots")} <MoreOutlined />
              {t("in the top right corner")}
              <div style={{ textAlign: "center" }}>
                <img
                  src={
                    nameSupport === "Instagram"
                      ? t("instagram_navbar")
                      : t("snapchat_navbar")
                  }
                  alt="face_right"
                  width="100%"
                />
              </div>
            </li>
            <li>
              <Trans i18nKey={"select_open_external"} t={t}>
                Select <b> Open in external browser </b>
              </Trans>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <img
                  src={
                    nameSupport === "Instagram"
                      ? t("instagram_external")
                      : t("snapchat_external")
                  }
                  alt="load"
                  width="100%"
                />
              </div>
            </li>
          </ul>
        </Space>
      </Modal>
    </div>
  );
};

export default Register;
